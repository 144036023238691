import React, { useEffect, useState } from 'react';
import CustomTitle from '../../../components/UI/title/CustomTitle';
import '../home1.scss'
import '../home1-media.scss'
import './home17.scss'
import { Helmet } from 'react-helmet';
import MyButton from '../../../components/UI/button/MyButton';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import ProgressiveImage from '../../../components/ProgressiveImage/ProgressiveImage';
import Preload from '../../../components/Preload/Preload';
import Timer from '../components/timer/Timer';

const Home17 = ({ setButtonShow, sethomeShow, setButtonStatus, setBlockPaddingBottom, setHandShow, sessionId }) => {
    const history = useHistory();
    function documentFontSize() {
        if (window.innerWidth > 991 && window.innerWidth < 1800) {
            document.documentElement.style.fontSize = 'calc(100vw / 1440)';
            console.log(window.innerWidth)
        } else if (window.innerWidth > 1799) {
            document.documentElement.style.fontSize = '1.2px';
        } else {
            document.documentElement.style.fontSize = '';
        }
    }

    useEffect(() => {
        sethomeShow(true)
        setButtonStatus('green')
        setHandShow(false)
        setBlockPaddingBottom('0rem')
        setButtonShow(false)
        documentFontSize();
    }, []);

    const [isVisible, setIsVisible] = useState(window.innerWidth > 991);

    useEffect(() => {
        const handleResize = () => {
            setIsVisible(window.innerWidth > 991);
            documentFontSize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'form_start'
        });

        mixpanel.track('web_start', {
            session_id: sessionId,
            web_entrance: 'signup',
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
            funnel: 'MAIN_v1',
        });
        const data = {
            "pageId": localStorage.getItem('HomeId')
        };
        setTimeout(() => {
            history.push('/stage');
        }, 150);

        axios.post("https://staging.zeely.link/pages/landings", data, {
            headers: {
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        })
            .then(response => {

            })
            .catch(error => {

            });

    }
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = () => {
        setTimeout(() => {
            setIsLoaded(true);
        }, 1000);
    };

    return (
        <div>

            {isVisible ? (
                <div style={{ paddingBottom: "32rem" }} className='home17'>
                    <Helmet>

                        <meta name="theme-color" content="#000"></meta>
                        <title>Black Friday 70% OFF sale discount on all-in-one AI ads tool</title>
                    </Helmet>
                    

                    <div className="home17__logo">
                        <svg width="79" height="22" viewBox="0 0 79 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_11675_13503)">
                                <path d="M45.8492 11.0493C45.8245 9.19955 45.2074 7.6704 43.973 6.46188C42.7387 5.27803 41.1835 4.6861 39.2826 4.6861C37.3818 4.6861 35.8019 5.27803 34.5676 6.46188C33.3086 7.6704 32.6914 9.17489 32.6914 11.0247C32.6914 12.8744 33.3086 14.3789 34.5676 15.5628C35.8019 16.7466 37.3818 17.3386 39.2826 17.3386C40.7885 17.3386 42.1463 16.9193 43.3312 16.0561C44.5161 15.1928 45.2814 14.1076 45.627 12.7758H42.3931C41.9241 13.8856 40.7145 14.7242 39.3073 14.7242C37.6287 14.7242 36.2956 13.6144 35.9006 12.0112H45.7505C45.8245 11.7399 45.8492 11.4193 45.8492 11.0493ZM39.3073 7.30045C40.8626 7.30045 42.2203 8.21301 42.714 9.56951H36.0241C36.5425 8.18834 37.8015 7.30045 39.3073 7.30045Z" fill="white" />
                                <path d="M59.8384 11.0493C59.8137 9.19955 59.1966 7.6704 57.9623 6.46188C56.728 5.27803 55.1727 4.6861 53.2719 4.6861C51.371 4.6861 49.7911 5.27803 48.5568 6.46188C47.2978 7.6704 46.6806 9.17489 46.6806 11.0247C46.6806 12.8744 47.2978 14.3789 48.5568 15.5628C49.7911 16.7466 51.371 17.3386 53.2719 17.3386C54.7777 17.3386 56.1355 16.9193 57.3204 16.0561C58.5054 15.1928 59.2706 14.1076 59.6163 12.7758H56.3823C55.9133 13.8856 54.7037 14.7242 53.2966 14.7242C51.6179 14.7242 50.2848 13.6144 49.8898 12.0112H59.7397C59.8137 11.7399 59.8384 11.4193 59.8384 11.0493ZM53.2966 7.30045C54.8518 7.30045 56.2095 8.21301 56.7033 9.56951H50.0133C50.5317 8.18834 51.7907 7.30045 53.2966 7.30045Z" fill="white" />
                                <path d="M61.3364 17.1166H64.5456V0H61.3364V17.1166Z" fill="white" />
                                <path d="M72.2983 12.6031L68.8915 4.93274H65.4602L70.7924 15.8094L67.6572 22H71.0146L78.9882 4.93274H75.6309L72.2983 12.6031Z" fill="white" />
                                <path d="M28.8741 4.93164H32.542L24.1367 17.1159H20.4688L28.8741 4.93164Z" fill="white" />
                                <path d="M20.8008 4.93164H29.8035V7.67907H20.8008V4.93164Z" fill="white" />
                                <path d="M23.2812 14.3672H31.9378V17.1146H23.2812V14.3672Z" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.61175 14.0781L0.611145 14.0767H15.4586L15.458 14.0781H0.61175ZM15.8489 12.8775C15.8003 13.0803 15.7439 13.2801 15.6802 13.4766H0.389549C0.32584 13.2801 0.26951 13.0803 0.220887 12.8775H15.8489ZM3.80845 17.8286C3.80923 17.8291 3.81001 17.8296 3.8108 17.8301H12.259C12.2598 17.8296 12.2605 17.8291 12.2613 17.8286H3.80845ZM2.9654 17.2285C2.73484 17.041 2.51489 16.8409 2.3066 16.6294H13.7632C13.5549 16.8409 13.3349 17.041 13.1044 17.2285H2.9654ZM0.891041 14.6782C1.02491 14.9373 1.17249 15.1881 1.33281 15.4297H14.737C14.8973 15.1881 15.0449 14.9373 15.1787 14.6782H0.891041ZM8.03489 19.0275C6.95635 19.0275 5.92745 18.8152 4.98777 18.4302H11.082C10.1423 18.8152 9.11342 19.0275 8.03489 19.0275ZM0.0287811 11.6836C0.0118963 11.4833 0.00237493 11.2809 0.000488281 11.0767H16.0693C16.0674 11.2809 16.0579 11.4833 16.041 11.6836H0.0287811ZM0.0780392 9.87891C0.113774 9.62373 0.161519 9.3724 0.220685 9.1255H15.8491C15.9083 9.3724 15.956 9.62373 15.9917 9.87891H0.0780392ZM0.390071 8.52394L0.3896 8.52539H15.6802L15.6797 8.52394H0.390071ZM0.611815 7.92383C0.696693 7.71973 0.78976 7.51989 0.890606 7.32472H15.1792C15.28 7.51989 15.3731 7.71973 15.458 7.92383H0.611815ZM1.6522 6.125C1.81272 5.91562 1.98334 5.71438 2.16332 5.52198H13.9065C14.0864 5.71438 14.257 5.91562 14.4176 6.125H1.6522ZM8.03489 2.97461C9.68532 2.97461 11.2195 3.47171 12.4956 4.32422H3.57413C4.85026 3.47171 6.38446 2.97461 8.03489 2.97461Z" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90363 6.125C7.23907 5.68775 7.61854 5.28597 8.03541 4.92628H2.78534C2.36847 5.28597 1.98901 5.68775 1.65356 6.125H6.90363ZM6.48513 6.72511H1.23507C0.994319 7.10618 0.784785 7.5089 0.610052 7.92969H5.86012C6.03485 7.5089 6.24439 7.10618 6.48513 6.72511ZM0.0781739 9.87891C0.143209 9.41533 0.247884 8.96445 0.388666 8.5298H5.63873C5.49795 8.96445 5.39327 9.41533 5.32824 9.87891H0.0781739ZM0.0168479 10.479C0.00567352 10.6522 0 10.827 0 11.003C0 11.2309 0.00950828 11.4566 0.0281502 11.6797H5.27822C5.25957 11.4566 5.25007 11.2309 5.25007 11.003C5.25007 10.827 5.25574 10.6522 5.26691 10.479H0.0168479ZM0.38941 13.4785C0.26377 13.091 0.166831 12.6906 0.101097 12.2798H5.35116C5.4169 12.6906 5.51384 13.091 5.63948 13.4785H0.38941ZM0.611006 14.0786C0.808476 14.5537 1.05032 15.0058 1.33137 15.4297H6.58144C6.30039 15.0058 6.05854 14.5537 5.86107 14.0786H0.611006ZM2.96286 17.2285C2.52465 16.8718 2.12482 16.4699 1.7705 16.0298H7.02056C7.37488 16.4699 7.77472 16.8718 8.21293 17.2285H2.96286ZM3.80515 17.8286C5.03362 18.5899 6.4828 19.0295 8.03475 19.0295C8.95398 19.0295 9.83716 18.8752 10.6598 18.5913C10.0936 18.3959 9.55606 18.139 9.05522 17.8286H3.80515ZM10.6598 3.4147C10.0054 3.64055 9.38942 3.94848 8.82406 4.32617H3.57399C4.85012 3.47367 6.38432 2.97656 8.03475 2.97656C8.95398 2.97656 9.83716 3.13077 10.6598 3.4147Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_11675_13503">
                                    <rect width="79" height="22" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="home17-content">
                        <div className="home17-left">
                            <h1>
                                <strong>Black Friday</strong><span>70% OFF</span> sale discount on all-in-one AI ads tool
                            </h1>
                            <div className="home17-action">
                                <Timer />
                            </div>
                            <div className="hero-featured">
                                <p>Zeely has been featured in:</p>
                                <div className="hero-featured-wrapper">
                                    <img src={`${process.env.PUBLIC_URL}/img/forbes.svg`} alt="" />
                                    <img src={`${process.env.PUBLIC_URL}/img/tc.svg`} alt="" />
                                    <img src={`${process.env.PUBLIC_URL}/img/sifted.svg`} alt="" />
                                    <img src={`${process.env.PUBLIC_URL}/img/startups.svg`} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="home17-right">
                            <div className="home17-right-img">
                                <ProgressiveImage
                                    lowQualitySrc={`${process.env.PUBLIC_URL}/img/home/home17-pc-low-quality.webp`}
                                    highQualitySrc={`${process.env.PUBLIC_URL}/img/home/home17-pc.webp`}
                                    handleImageLoad={handleImageLoad}
                                />
                            </div>
                            <div style={{ position: 'relative' }}>
                                <MyButton buttonText={'Get started'} buttonStatus={'green'} onClick={() => handleClick()} />
                                <div className="hand" >
                                    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_11035_53290)">
                                            <path d="M15.323 14.04C13.5422 15.0681 12.9223 17.3819 13.9504 19.1626L21.3335 31.9506L20.1934 31.6158L19.7859 31.535C18.8377 31.2832 17.8283 31.4172 16.9787 31.9077C16.129 32.3983 15.5083 33.2054 15.2522 34.1525C15.0004 35.1007 15.1345 36.11 15.625 36.9597C16.1155 37.8093 16.9227 38.4301 17.8697 38.6861L17.8891 38.7197L31.8116 42.3642L31.8981 42.359L31.9711 42.4078C34.2106 42.6893 36.4809 42.2333 38.4381 41.109L40.3 40.034C41.4661 39.3641 42.4883 38.4704 43.3081 37.4043C44.1278 36.3382 44.7289 35.1207 45.0769 33.8217C45.4249 32.5227 45.5129 31.1678 45.3358 29.8347C45.1588 28.5016 44.7202 27.2165 44.0452 26.0534L39.1627 17.5967C38.1346 15.8159 35.8198 15.1966 34.0401 16.2241C33.579 16.4903 33.2256 16.8632 32.9297 17.2707C31.7621 16.0685 29.8684 15.7459 28.3583 16.6177C27.529 17.0965 26.9555 17.8606 26.6813 18.7132C25.7774 18.5142 24.8317 18.6584 24.0281 19.1177C23.6609 19.3359 23.3344 19.6163 23.0631 19.9463L20.4456 15.4126C19.4175 13.6319 17.1038 13.0119 15.323 14.04Z" fill="white"></path>
                                            <path d="M15.323 14.04C13.5422 15.0681 12.9223 17.3819 13.9504 19.1626L21.3335 31.9506L20.1934 31.6158L19.7859 31.535C18.8377 31.2832 17.8283 31.4172 16.9787 31.9077C16.129 32.3983 15.5083 33.2054 15.2522 34.1525C15.0004 35.1007 15.1345 36.11 15.625 36.9597C16.1155 37.8093 16.9227 38.4301 17.8697 38.6861L17.8891 38.7197L31.8116 42.3642L31.8981 42.359L31.9711 42.4078C34.2106 42.6893 36.4809 42.2333 38.4381 41.109L40.3 40.034C41.4661 39.3641 42.4883 38.4704 43.3081 37.4043C44.1278 36.3382 44.7289 35.1207 45.0769 33.8217C45.4249 32.5227 45.5129 31.1678 45.3358 29.8347C45.1588 28.5016 44.7202 27.2165 44.0452 26.0534L39.1627 17.5967C38.1346 15.8159 35.8198 15.1966 34.0401 16.2241C33.579 16.4903 33.2256 16.8632 32.9297 17.2707C31.7621 16.0685 29.8684 15.7459 28.3583 16.6177C27.529 17.0965 26.9555 17.8606 26.6813 18.7132C25.7774 18.5142 24.8317 18.6584 24.0281 19.1177C23.6609 19.3359 23.3344 19.6163 23.0631 19.9463L20.4456 15.4126C19.4175 13.6319 17.1038 13.0119 15.323 14.04ZM16.573 16.2051C17.1738 15.8582 17.9337 16.0618 18.2805 16.6626L25.1555 28.5705L27.3206 27.3205L24.8206 22.9903C24.4737 22.3895 24.6773 21.6297 25.2781 21.2828C25.8789 20.9359 26.6388 21.1395 26.9857 21.7403L29.4857 26.0705L31.6507 24.8205L29.1507 20.4903C28.8038 19.8895 29.0074 19.1297 29.6083 18.7828C30.2091 18.4359 30.9689 18.6395 31.3158 19.2403L33.8158 23.5705L36.0826 22.2617L34.8326 20.0967C34.4857 19.4958 34.6893 18.736 35.2901 18.3891C35.8899 18.0429 36.6508 18.2458 36.9977 18.8467L41.8808 27.3045C44.0458 31.0544 42.7999 35.704 39.049 37.8696L37.1892 38.9433C35.6033 39.859 33.9059 40.0841 32.2744 39.8857L18.5124 36.2842C17.7525 36.0806 17.4494 35.5556 17.653 34.7957C17.8577 34.0353 18.3817 33.7328 19.1415 33.9364L26.5061 35.9097L16.1155 17.9126C15.7686 17.3118 15.9722 16.552 16.573 16.2051Z" fill="black"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_11035_53290">
                                                <rect width="40" height="40" fill="white" transform="translate(0 20) rotate(-30)"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            ) : (
                <div className='home17'>
                    <Helmet>

                        <meta name="theme-color" content="#000"></meta>
                        <title>Black Friday 70% OFF sale discount on all-in-one AI ads tool</title>
                    </Helmet>
                    <div className="home17__logo">
                        <svg width="79" height="22" viewBox="0 0 79 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_11675_13503)">
                                <path d="M45.8492 11.0493C45.8245 9.19955 45.2074 7.6704 43.973 6.46188C42.7387 5.27803 41.1835 4.6861 39.2826 4.6861C37.3818 4.6861 35.8019 5.27803 34.5676 6.46188C33.3086 7.6704 32.6914 9.17489 32.6914 11.0247C32.6914 12.8744 33.3086 14.3789 34.5676 15.5628C35.8019 16.7466 37.3818 17.3386 39.2826 17.3386C40.7885 17.3386 42.1463 16.9193 43.3312 16.0561C44.5161 15.1928 45.2814 14.1076 45.627 12.7758H42.3931C41.9241 13.8856 40.7145 14.7242 39.3073 14.7242C37.6287 14.7242 36.2956 13.6144 35.9006 12.0112H45.7505C45.8245 11.7399 45.8492 11.4193 45.8492 11.0493ZM39.3073 7.30045C40.8626 7.30045 42.2203 8.21301 42.714 9.56951H36.0241C36.5425 8.18834 37.8015 7.30045 39.3073 7.30045Z" fill="white" />
                                <path d="M59.8384 11.0493C59.8137 9.19955 59.1966 7.6704 57.9623 6.46188C56.728 5.27803 55.1727 4.6861 53.2719 4.6861C51.371 4.6861 49.7911 5.27803 48.5568 6.46188C47.2978 7.6704 46.6806 9.17489 46.6806 11.0247C46.6806 12.8744 47.2978 14.3789 48.5568 15.5628C49.7911 16.7466 51.371 17.3386 53.2719 17.3386C54.7777 17.3386 56.1355 16.9193 57.3204 16.0561C58.5054 15.1928 59.2706 14.1076 59.6163 12.7758H56.3823C55.9133 13.8856 54.7037 14.7242 53.2966 14.7242C51.6179 14.7242 50.2848 13.6144 49.8898 12.0112H59.7397C59.8137 11.7399 59.8384 11.4193 59.8384 11.0493ZM53.2966 7.30045C54.8518 7.30045 56.2095 8.21301 56.7033 9.56951H50.0133C50.5317 8.18834 51.7907 7.30045 53.2966 7.30045Z" fill="white" />
                                <path d="M61.3364 17.1166H64.5456V0H61.3364V17.1166Z" fill="white" />
                                <path d="M72.2983 12.6031L68.8915 4.93274H65.4602L70.7924 15.8094L67.6572 22H71.0146L78.9882 4.93274H75.6309L72.2983 12.6031Z" fill="white" />
                                <path d="M28.8741 4.93164H32.542L24.1367 17.1159H20.4688L28.8741 4.93164Z" fill="white" />
                                <path d="M20.8008 4.93164H29.8035V7.67907H20.8008V4.93164Z" fill="white" />
                                <path d="M23.2812 14.3672H31.9378V17.1146H23.2812V14.3672Z" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.61175 14.0781L0.611145 14.0767H15.4586L15.458 14.0781H0.61175ZM15.8489 12.8775C15.8003 13.0803 15.7439 13.2801 15.6802 13.4766H0.389549C0.32584 13.2801 0.26951 13.0803 0.220887 12.8775H15.8489ZM3.80845 17.8286C3.80923 17.8291 3.81001 17.8296 3.8108 17.8301H12.259C12.2598 17.8296 12.2605 17.8291 12.2613 17.8286H3.80845ZM2.9654 17.2285C2.73484 17.041 2.51489 16.8409 2.3066 16.6294H13.7632C13.5549 16.8409 13.3349 17.041 13.1044 17.2285H2.9654ZM0.891041 14.6782C1.02491 14.9373 1.17249 15.1881 1.33281 15.4297H14.737C14.8973 15.1881 15.0449 14.9373 15.1787 14.6782H0.891041ZM8.03489 19.0275C6.95635 19.0275 5.92745 18.8152 4.98777 18.4302H11.082C10.1423 18.8152 9.11342 19.0275 8.03489 19.0275ZM0.0287811 11.6836C0.0118963 11.4833 0.00237493 11.2809 0.000488281 11.0767H16.0693C16.0674 11.2809 16.0579 11.4833 16.041 11.6836H0.0287811ZM0.0780392 9.87891C0.113774 9.62373 0.161519 9.3724 0.220685 9.1255H15.8491C15.9083 9.3724 15.956 9.62373 15.9917 9.87891H0.0780392ZM0.390071 8.52394L0.3896 8.52539H15.6802L15.6797 8.52394H0.390071ZM0.611815 7.92383C0.696693 7.71973 0.78976 7.51989 0.890606 7.32472H15.1792C15.28 7.51989 15.3731 7.71973 15.458 7.92383H0.611815ZM1.6522 6.125C1.81272 5.91562 1.98334 5.71438 2.16332 5.52198H13.9065C14.0864 5.71438 14.257 5.91562 14.4176 6.125H1.6522ZM8.03489 2.97461C9.68532 2.97461 11.2195 3.47171 12.4956 4.32422H3.57413C4.85026 3.47171 6.38446 2.97461 8.03489 2.97461Z" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90363 6.125C7.23907 5.68775 7.61854 5.28597 8.03541 4.92628H2.78534C2.36847 5.28597 1.98901 5.68775 1.65356 6.125H6.90363ZM6.48513 6.72511H1.23507C0.994319 7.10618 0.784785 7.5089 0.610052 7.92969H5.86012C6.03485 7.5089 6.24439 7.10618 6.48513 6.72511ZM0.0781739 9.87891C0.143209 9.41533 0.247884 8.96445 0.388666 8.5298H5.63873C5.49795 8.96445 5.39327 9.41533 5.32824 9.87891H0.0781739ZM0.0168479 10.479C0.00567352 10.6522 0 10.827 0 11.003C0 11.2309 0.00950828 11.4566 0.0281502 11.6797H5.27822C5.25957 11.4566 5.25007 11.2309 5.25007 11.003C5.25007 10.827 5.25574 10.6522 5.26691 10.479H0.0168479ZM0.38941 13.4785C0.26377 13.091 0.166831 12.6906 0.101097 12.2798H5.35116C5.4169 12.6906 5.51384 13.091 5.63948 13.4785H0.38941ZM0.611006 14.0786C0.808476 14.5537 1.05032 15.0058 1.33137 15.4297H6.58144C6.30039 15.0058 6.05854 14.5537 5.86107 14.0786H0.611006ZM2.96286 17.2285C2.52465 16.8718 2.12482 16.4699 1.7705 16.0298H7.02056C7.37488 16.4699 7.77472 16.8718 8.21293 17.2285H2.96286ZM3.80515 17.8286C5.03362 18.5899 6.4828 19.0295 8.03475 19.0295C8.95398 19.0295 9.83716 18.8752 10.6598 18.5913C10.0936 18.3959 9.55606 18.139 9.05522 17.8286H3.80515ZM10.6598 3.4147C10.0054 3.64055 9.38942 3.94848 8.82406 4.32617H3.57399C4.85012 3.47367 6.38432 2.97656 8.03475 2.97656C8.95398 2.97656 9.83716 3.13077 10.6598 3.4147Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_11675_13503">
                                    <rect width="79" height="22" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <div className="home17-wrapper">

                   
                        <div className="home17-content" style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginBottom: '32rem' }}>

                            <div className="home17-content__img">

                                <ProgressiveImage
                                    lowQualitySrc={`${process.env.PUBLIC_URL}/img/home/home17-low-quality.webp`}
                                    highQualitySrc={`${process.env.PUBLIC_URL}/img/home/home17.webp`}
                                    handleImageLoad={handleImageLoad}
                                />
                            </div>
                        </div>
                        <h1>
                            <strong>Black Friday</strong><span>70% OFF</span> sale discount on all-in-one AI ads tool
                        </h1>
                    </div>


                    <div style={{ position: 'relative', width: '100%', zIndex: '2' }}>
                        <MyButton buttonText={'Save 70% now '} buttonStatus={'green'}  onClick={() => handleClick()} />
                        <div className="hand" >
                            <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_11035_53290)">
                                    <path d="M15.323 14.04C13.5422 15.0681 12.9223 17.3819 13.9504 19.1626L21.3335 31.9506L20.1934 31.6158L19.7859 31.535C18.8377 31.2832 17.8283 31.4172 16.9787 31.9077C16.129 32.3983 15.5083 33.2054 15.2522 34.1525C15.0004 35.1007 15.1345 36.11 15.625 36.9597C16.1155 37.8093 16.9227 38.4301 17.8697 38.6861L17.8891 38.7197L31.8116 42.3642L31.8981 42.359L31.9711 42.4078C34.2106 42.6893 36.4809 42.2333 38.4381 41.109L40.3 40.034C41.4661 39.3641 42.4883 38.4704 43.3081 37.4043C44.1278 36.3382 44.7289 35.1207 45.0769 33.8217C45.4249 32.5227 45.5129 31.1678 45.3358 29.8347C45.1588 28.5016 44.7202 27.2165 44.0452 26.0534L39.1627 17.5967C38.1346 15.8159 35.8198 15.1966 34.0401 16.2241C33.579 16.4903 33.2256 16.8632 32.9297 17.2707C31.7621 16.0685 29.8684 15.7459 28.3583 16.6177C27.529 17.0965 26.9555 17.8606 26.6813 18.7132C25.7774 18.5142 24.8317 18.6584 24.0281 19.1177C23.6609 19.3359 23.3344 19.6163 23.0631 19.9463L20.4456 15.4126C19.4175 13.6319 17.1038 13.0119 15.323 14.04Z" fill="white"></path>
                                    <path d="M15.323 14.04C13.5422 15.0681 12.9223 17.3819 13.9504 19.1626L21.3335 31.9506L20.1934 31.6158L19.7859 31.535C18.8377 31.2832 17.8283 31.4172 16.9787 31.9077C16.129 32.3983 15.5083 33.2054 15.2522 34.1525C15.0004 35.1007 15.1345 36.11 15.625 36.9597C16.1155 37.8093 16.9227 38.4301 17.8697 38.6861L17.8891 38.7197L31.8116 42.3642L31.8981 42.359L31.9711 42.4078C34.2106 42.6893 36.4809 42.2333 38.4381 41.109L40.3 40.034C41.4661 39.3641 42.4883 38.4704 43.3081 37.4043C44.1278 36.3382 44.7289 35.1207 45.0769 33.8217C45.4249 32.5227 45.5129 31.1678 45.3358 29.8347C45.1588 28.5016 44.7202 27.2165 44.0452 26.0534L39.1627 17.5967C38.1346 15.8159 35.8198 15.1966 34.0401 16.2241C33.579 16.4903 33.2256 16.8632 32.9297 17.2707C31.7621 16.0685 29.8684 15.7459 28.3583 16.6177C27.529 17.0965 26.9555 17.8606 26.6813 18.7132C25.7774 18.5142 24.8317 18.6584 24.0281 19.1177C23.6609 19.3359 23.3344 19.6163 23.0631 19.9463L20.4456 15.4126C19.4175 13.6319 17.1038 13.0119 15.323 14.04ZM16.573 16.2051C17.1738 15.8582 17.9337 16.0618 18.2805 16.6626L25.1555 28.5705L27.3206 27.3205L24.8206 22.9903C24.4737 22.3895 24.6773 21.6297 25.2781 21.2828C25.8789 20.9359 26.6388 21.1395 26.9857 21.7403L29.4857 26.0705L31.6507 24.8205L29.1507 20.4903C28.8038 19.8895 29.0074 19.1297 29.6083 18.7828C30.2091 18.4359 30.9689 18.6395 31.3158 19.2403L33.8158 23.5705L36.0826 22.2617L34.8326 20.0967C34.4857 19.4958 34.6893 18.736 35.2901 18.3891C35.8899 18.0429 36.6508 18.2458 36.9977 18.8467L41.8808 27.3045C44.0458 31.0544 42.7999 35.704 39.049 37.8696L37.1892 38.9433C35.6033 39.859 33.9059 40.0841 32.2744 39.8857L18.5124 36.2842C17.7525 36.0806 17.4494 35.5556 17.653 34.7957C17.8577 34.0353 18.3817 33.7328 19.1415 33.9364L26.5061 35.9097L16.1155 17.9126C15.7686 17.3118 15.9722 16.552 16.573 16.2051Z" fill="black"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_11035_53290">
                                        <rect width="40" height="40" fill="white" transform="translate(0 20) rotate(-30)"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>

                </div>
            )}
        </div>


    );
}

export default Home17;
