import React, { useEffect, useState } from 'react'
import './style.scss'

export default function Timer({text}) {
    const targetDate = new Date('November 29, 2024 23:59:00').getTime();
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    function calculateTimeLeft() {
        const now = new Date().getTime();
        const difference = targetDate - now;

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const borderBlock = <svg width="56" height="72" viewBox="0 0 56 72" fill="none" preserveAspectRatio="xMinYMin slice" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_11675_13063)">
            <rect width="56" height="72" rx="7.01467" fill="black" />
            <rect x="0.438417" y="0.438417" width="55.1232" height="71.1232" rx="6.57625" stroke="url(#paint0_linear_11675_13063)" stroke-opacity="0.39" stroke-width="0.876833" />
        </g>
        <defs>
            <filter id="filter0_b_11675_13063" x="-11.2235" y="-11.2235" width="78.4469" height="94.4469" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.61173" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11675_13063" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_11675_13063" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_11675_13063" x1="28" y1="0" x2="28" y2="72" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5BF0A5" />
                <stop offset="1" stop-color="#DBFFED" />
            </linearGradient>
        </defs>
    </svg>

    return (
        <div className="home-timer">
            <div className="home-timer-wrapper">
                <span>
                    {borderBlock}

                    {timeLeft.days}
                    <b>days</b>
                </span>
                <i>:</i>
                <span>
                    {borderBlock}
                    {timeLeft.hours}
                    <b>hours</b>
                </span>
                <i>:</i>
                <span>
                    {borderBlock}
                    {timeLeft.minutes}
                    <b>mins</b>
                </span>
                <i>:</i>
                <span>
                    {borderBlock}
                    {timeLeft.seconds}
                    <b>secs</b>
                </span>
            </div>
            <p dangerouslySetInnerHTML={{ __html: text || 'Hurry up! <br />Limited-time deal.' }}></p>
        </div>
    )
}
