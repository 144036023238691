import React, { useEffect, useState } from 'react'
import './style.scss'

export default function Notification() {

    const items = [
        {
            name: 'Alice Torello',
            text: 'You received a new order to $109.99',
        },
        {
            name: 'Remy Callahan',
            text: 'You received a new order to $99.95',
        },
        {
            name: 'Ella Montgomery',
            text: 'You received a new order to $279.99',
        }, {
            name: 'Alice Torello',
            text: 'You received a new order to $109.99',
        },
        {
            name: 'Remy Callahan',
            text: 'You received a new order to $99.95',
        },
        {
            name: 'Ella Montgomery',
            text: 'You received a new order to $279.99',
        },
    ];
    const [activeIndex, setActiveIndex] = useState(1);
    const length = items.length;

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % length);
        }, 1800);

        return () => clearInterval(interval);
    }, [length]);

    const getClassName = (index) => {
        if (index === activeIndex) return "home-notification-item active";
        if (index === (activeIndex + 1) % length) return "home-notification-item next";
        if (index === (activeIndex - 1 + length) % length) return "home-notification-item prev";
        return "home-notification-item";
    };
    return (

        <div className="home-notification">
            {items.map((item, index) => (
                <div key={index} className={getClassName(index)}>
                    <div className="home-notification-item__img">
                        <img src={`${process.env.PUBLIC_URL}/img/home/notification-icon.png`} alt="" />
                    </div>
                    <div className="home-notification-item-info">
                        <strong>{item.name}</strong>
                        <span>{item.text}</span>
                    </div>
                    <div className="home-notification-item__now">
                        Now
                    </div>

                </div>
            ))}
        </div>
    )
}
